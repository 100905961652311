<template>
  <div class="card instagram-card">
    <div class="title">
      <img src="@/assets/images/instagram-icon.svg" alt="" />
      <h2>{{profileName}}</h2>
    </div>
    <img v-if="image" class="image" :src="image" alt="" />
    <div><CardContentHighlight :text="text" :terms="glossaryTerms" /></div>
    <SocialCountBar
      :thumbs_up="thumbs_up"
      :share="share"
      :comments="comments"
    />
  </div>
</template>

<script>
import CardContentHighlight from "@/components/CardContentHighlight";
import SocialCountBar from '@/components/cards/SocialCountBar'

export default {
  name: "InstagramCard",
  props: {
    image: {
      type: Object,
    },
    text: {
      type: String,
    },
    glossaryTerms: {
      type: Array,
    },
    thumbs_up: {
      type: Number,
    },
    share: {
      type: Number,
    },
    comments: {
      type: Number,
    },
    profileName:{
      type: String
    }
  },
  components: {
    CardContentHighlight,
    SocialCountBar
  },
};
</script>
