<template>
  <div class="social-count-bar">
    <div>
      <img src="@/assets/images/thumbs-up-icon.svg" alt="" />
      <span>{{ thumbs_up }}</span>
    </div>
    <div>
      <img src="@/assets/images/comment-icon.svg" alt="" />
      <span>{{ comments }}</span>
    </div>
    <div>
      <img src="@/assets/images/share-icon.svg" alt="" />
      <span>{{ share }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialCountBar",
  props: {
    thumbs_up: {
      type: Number,
    },
    share: {
      type: Number,
    },
    comments: {
      type: Number,
    },
  },
};
</script>
