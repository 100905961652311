<template>
  <div>
    <Menu
      :selectedSlide="selectedSlide"
      :slides="totalSlides"
      :cardTitle="cardTitle"
      :instructions="instructions"
    />
    <div class="content">
      <div class="inner-content">
        <div>
          <div class="slide" v-for="(card, index) in cards" :key="index">
            <div class="inner-slide" v-show="selectedSlide === index + 1">
              <FacebookCard
                v-if="card.type === 'facebook'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
                :thumbs_up="card.thumbs_up"
                :comments="card.comments"
                :share="card.share"
                :profileName="card.profile_name"
              />
              <YoutubeCard
                v-if="card.type === 'youtube'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
                :video="card.url"
              />
              <InstagramCard
                v-if="card.type === 'instagram'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
                :thumbs_up="card.thumbs_up"
                :comments="card.comments"
                :share="card.share"
                :profileName="card.profile_name"
              />
              <TwitterCard
                v-if="card.type === 'twitter'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
                :thumbs_up="card.thumbs_up"
                :comments="card.comments"
                :share="card.share"
                :profileName="card.profile_name"
              />
              <FactCheckCard
                v-if="card.type === 'fact-check'"
                :factIntro="card.fact_check_intro"
                :factTruth="card.fact_check_truth"
                :factClaim="card.fact_check_claim"
                :glossaryTerms="card.glossary_terms"
              />
              <ImageCard
                v-if="card.type === 'image'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
              />
              <TextCard
                v-if="card.type === 'text'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
              />
              <div
                class="card form-card"
                :class="{ submitted: submitted }"
                v-if="card.answers"
              >
                <form
                  action=""
                  @submit="checkForm($event, card._id)"
                  v-if="!submitted"
                >
                  <label for="team_name">Team name</label>
                  <input
                    type="text"
                    v-model="teamName"
                    :class="{ error: errors.length > 0 }"
                  />
                  <label for="message">Message</label>
                  <textarea
                    :class="{ error: errors.length > 0 }"
                    id="message"
                    name="message"
                    v-model="message"
                  ></textarea>
                  <label for="file">Upload image</label>
                  <div class="file-uploader">
                    <div class="btn" @click="chooseFile()">Choose File</div>
                    <span v-if="!fileName">No file choosen</span>
                    <span v-if="fileName">{{ fileName }}</span>
                  </div>
                  <input
                    type="file"
                    id="file"
                    ref="fileInput"
                    @change="changeFileName()"
                  />
                  <input type="submit" value="Send answers" />
                </form>
                <span v-if="submitted" class="submitted-answers"
                  >Your answers are submitted!</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-control">
          <a
            class="btn-primary prev-slide"
            v-if="selectedSlide != 1"
            @click="prevSlide()"
          >
            Prev slide
          </a>
          <a
            class="btn-primary next-slide"
            v-if="selectedSlide != slide"
            @click="nextSlide()"
          >
            Next slide
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacebookCard from "@/components/cards/facebookCard";
import YoutubeCard from "@/components/cards/youtubeCard";
import InstagramCard from "@/components/cards/instagramCard";
import TwitterCard from "@/components/cards/twitterCard";
import FactCheckCard from "@/components/cards/factCheckCard";
import ImageCard from "@/components/cards/imageCard";
import TextCard from "@/components/cards/textCard";
import Menu from "@/components/Menu.vue";
import sanity from "../sanity";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);
const query = `*[_type == "training" && slug.current == $slug && enable == true]`;
const cardQuery = `*[_type == "training" && slug.current == $slug && enable == true] {
  "relatedCards": cards[]->{_id, answers, url, profile_name, fact_check_intro, fact_check_claim, fact_check_truth, type, title, image, content, thumbs_up, comments, share, introduction, glossary_terms[]->{term_name, term_text}}
}`;

export default {
  name: "Training",
  components: {
    FacebookCard,
    YoutubeCard,
    InstagramCard,
    TwitterCard,
    FactCheckCard,
    ImageCard,
    TextCard,
    Menu,
  },
  data() {
    return {
      selectedSlide: 1,
      slide: null,
      cards: null,
      totalSlides: null,
      message: null,
      teamName: null,
      fileName: null,
      errors: false,
      submitted: null,
      interval: null,
      cardTitle: null,
      instructions: null,
      sanityDocument: null,
      currentSlide: localStorage.getItem("currentSlide")
        ? parseInt(localStorage.getItem("currentSlide"))
        : 1,
    };
  },
  created() {
    sanity
      .fetch(query, { slug: this.$route.params.slug })
      .then((response) => {
        let data = response[0]
        this.sanityDocument = data._id;
        this.slide = data.training_slide;

        if (!localStorage.getItem("currentSlide")) {
          this.selectedSlide = 1;
        } else {
          this.selectedSlide = parseInt(localStorage.getItem("currentSlide"));
          if (this.selectedSlide > this.slide) {
            this.selectedSlide = 1;
            localStorage.setItem("currentSlide", 1);
          }
        }
      })
      .catch(() => this.$router.push({ name: "PageNotFound" }));

    sanity
      .fetch(cardQuery, { slug: this.$route.params.slug })
      .then((response) => {
        let data = response[0]
        this.cards = data.relatedCards;
        this.totalSlides = this.cards.length;
        this.cardTitle = this.cards[0].title;
        this.instructions = this.cards[0].introduction;
      });
  },
  mounted() {
    this.interval = setInterval(() => this.getNewSlide(), 10000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    async getNewSlide() {
      sanity
        .fetch(query, { slug: this.$route.params.slug })
        .then((response) => {
          this.slide = response[0].training_slide;
          if (this.slide === this.totalSlides) {
            clearInterval(this.interval);
          }
        });
    },
    changeFileName() {
      this.fileName = this.$refs.fileInput[0].value.match(/[^\\/]*$/)[0];
    },
    chooseFile() {
      let fileInput = document.getElementById("file");
      fileInput.click();
    },
    checkForm(e, id) {
      if (this.message && this.teamName) {
        this.errors = false

        let image = this.$refs.fileInput[0].files[0];

        // If answers contain image, save it
        if (image != null) {
          sanity.assets
            .upload("image", image, {
              filename: image,
            })
            .then((imageAsset) => {
              sanity.create({
                _type: "answers",
                training: {
                  _type: "reference",
                  _ref: this.sanityDocument,
                },
                answers_to_card: {
                  _type: "reference",
                  _ref: id,
                },
                image: {
                  _type: "image",
                  asset: {
                    _type: "reference",
                    _ref: imageAsset._id,
                  },
                },
                team_name: this.teamName,
                team_answer: this.message,
              });
            });
        } else {
          // Answer doesn't include image
          sanity.create({
            _type: "answers",
            training: {
              _type: "reference",
              _ref: this.sanityDocument,
            },
            answers_to_card: {
              _type: "reference",
              _ref: id,
            },
            team_name: this.teamName,
            team_answer: this.message,
          });
        }
        // Show success message
        this.submitted = true;
      } else {
        this.errors = true
      }

      e.preventDefault();
    },
    imageUrlFor(source) {
      return imageBuilder.image(source)
    },
    clearData() {
      this.filename = null
      this.errors = false
      this.image = null
      this.teamName = null
      this.message = null
      this.submitted = null
    },
    nextSlide() {
      this.currentSlide += 1
      this.selectedSlide += 1
      localStorage.setItem("currentSlide", parseInt(this.currentSlide))
      this.clearData()
      window.scrollTo(0, 0)
      this.setTitleAndIntro()
    },
    prevSlide() {
      this.currentSlide -= 1
      this.selectedSlide -= 1
      localStorage.setItem("currentSlide", parseInt(this.currentSlide))
      this.clearData()
      window.scrollTo(0, 0)
      this.setTitleAndIntro()
    },
    setTitleAndIntro() {
      this.cardTitle = this.cards[this.selectedSlide - 1].title
      this.instructions = this.cards[this.selectedSlide - 1].introduction
    },
  },
};
</script>
