<template>
  <div class="card">
    <img v-if="image" class="image" :src="image" alt="" />
    <div><CardContentHighlight :text="text" :terms="glossaryTerms" /></div>
  </div>
</template>

<script>
import CardContentHighlight from "@/components/CardContentHighlight";

export default {
  name: "TextCard",
  props: {
    image: {
      type: Object,
    },
    text: {},
    glossaryTerms: {
      type: Array,
    },
  },
  components: {
    CardContentHighlight,
  }
};
</script>

<style lang="scss" scoped>
</style>
