<template>
  <ContentHighlight :content="highlight(contentText, termNames)" />
</template>

<script>
import sanity from "../sanity";
import blocksToHtml from "@sanity/block-content-to-html";
import ContentHighlight from "@/components/ContentHighlight";

export default {
  name: "CardContentHighlight",
  props: {
    text: {},
    terms: {},
  },
  components: {
    ContentHighlight,
  },
  data() {
    return {
      termNames: [],
      contentText: "",
      currentWord: "",
    };
  },
  created() {
    if (this.terms) {
      this.terms.forEach(function (element) {
        this.termNames.push(element.term_name)
      }, this);
    }

    const serializers = {}

    if (this.text) {
      this.contentText = blocksToHtml({
        blocks: this.text,
        serializers: serializers,
        dataset: sanity.clientConfig.dataset,
        projectId: sanity.clientConfig.projectId,
      });
    }
  },
  methods: {
    highlight(word, query) {
      if (query.length <= 0) {
        return word
      }

      var strMatcher

      query.forEach(function (v) {
        strMatcher += `|\\b${v}\\b`
      });

      var check = new RegExp(strMatcher, "ig")

      let routeSlug = this.$route.params.slug;
      return word.toString().replace(check, function (matchedText) {
        return `<router-link to="/glossary-term/${routeSlug}/${matchedText}">${matchedText}</router-link>`
      });
    },
  },
};
</script>