<template>
  <div class="content">
    <div class="inner-content">
      <div v-for="(cardAnswer, index) in cards" :key="index" class="answer">
        <h3 class="border-bt-primary">
          {{ cardAnswer.teamName }} / {{ cardAnswer.answersToCard.title }}
        </h3>
        <h4>Message</h4>
        <p>{{ cardAnswer.teamAnswer }}</p>
        <h4 v-if="cardAnswer.image">Image upload</h4>
        <img
          v-if="cardAnswer.image"
          :src="imageUrlFor(cardAnswer.image.asset._ref)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "../sanity";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);

const trainingQuery = `*[_type == "training" && slug.current == $slug && enable == true]`;
const cardQuery = `*[_type == "answers" && training._ref == $trainingId] | order(_createdAt asc){
  "answersToCard": answers_to_card->{title},
    "teamAnswer": team_answer,
    "teamName": team_name,
    "image": image
}`;

export default {
  name: "Answers",
  data() {
    return {
      cards: null,
      trainingId: null,
    };
  },
  created() {
    sanity
      .fetch(trainingQuery, { slug: this.$route.params.slug })
      .then((response) => {
        this.trainingId = response[0]._id;
        sanity
          .fetch(cardQuery, { trainingId: this.trainingId })
          .then((response) => {
            this.cards = response;
          });
      });
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 32px;
}

img {
  width: 100%;
  margin-bottom: 100px;
}
</style>