<template>
  <div id="highlight"></div>
</template>

<script>
import Vue from "vue";
import router from '../router'

export default {
  name: "ContentHighlight",
  props: {
    content: {
      type: String,
    },
  },
  mounted() {
    var res = Vue.compile(this.content);

    new Vue({
      el: "#highlight",
      router: router,
      render: res.render,
      staticRenderFns: res.staticRenderFns,
    });
  },
};
</script>